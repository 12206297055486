.SearchInput_wrapper__Al8pr {
    display: flex;
    flex-wrap: nowrap;
    gap: 12px;
    align-items: center;
    padding: 8px 8px 8px 20px;
    border-radius: 1000px;
    border-width: 1px;
    border-style: solid;
    overflow: hidden;
    transition: border-color 300ms cubic-bezier(0.23, 1, 0.32, 1);
    box-shadow: 0px 19px 25px 0px #0000000F, 0px 2px 4px 0px #0000000A;
}

    .SearchInput_wrapper__Al8pr:focus-within {
        border-color: #007ACC;
    }

    @supports (container-type: inline-size) {.SearchInput_wrapper__Al8pr {
        flex-wrap: wrap
}
    }

    @container (min-width: 320px) {.SearchInput_wrapper__Al8pr {
        flex-wrap: nowrap
}
    }

.SearchInput_wrapperMobileStack__GMPGL {
    display: flex;
    flex-direction: column;
    padding: 8px;
    border-radius: 24px;
    border-width: 1px;
    border-style: solid;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: border-color 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.SearchInput_wrapperMobileStack__GMPGL:focus-within {
        border-color: #007ACC;
    }

@container (min-width: 375px) {

.SearchInput_wrapperMobileStack__GMPGL {
        flex-direction: row;
        align-items: center;
        border-radius: 1000px;
        justify-content: space-between;
        padding: 8px 8px 8px 20px;
        gap: 12px
}

        .SearchInput_wrapperMobileStack__GMPGL div {
        padding-left: 0;
        padding-right: 0;
    }
    }

.SearchInput_wrapperBordered__ECBG_ {
    border-color: #CAD8E2;
    background-color: #FFFFFF;
}

.SearchInput_wrapperBordered__ECBG_:focus-within {
        background-color: #FFFFFF;
    }

.SearchInput_wrapperShadow__LV7gp {
    border-color: #FFFFFF;
    background-color: #FFFFFF;
    box-shadow: 0px 19px 25px 0px #0000000F, 0px 2px 4px 0px #0000000A;
}

.SearchInput_wrapperShadow__LV7gp:focus-within {
        background-color: #FFFFFF;
        box-shadow: 0px 19px 25px 0px #0000000F, 0px 2px 4px 0px #0000000A;
    }

@container (max-width: 319px) {

.SearchInput_hasButton__nZdoV {
        padding: 16px 16px 8px;
        border-radius: 24px
}
    }

.SearchInput_searchIconRight__lx72g {
    order: 2;
    padding: 8px;
    box-sizing: content-box;
}

.SearchInput_input__fBAVO {
    font-size: 14.222px;
    line-height: 150%;
    flex-grow: 1;
    min-width: 0;
    background-color: transparent; /* Safari adds a grey background colour by default. */
    color: #5A5E6E;
}

.SearchInput_input__fBAVO::-moz-placeholder {
        color: inherit;
        text-overflow: ellipsis;
    }

.SearchInput_input__fBAVO::placeholder {
        color: inherit;
        text-overflow: ellipsis;
    }

.SearchInput_input__fBAVO:-moz-placeholder-shown {
        text-overflow: ellipsis;
    }

.SearchInput_input__fBAVO:placeholder-shown {
        text-overflow: ellipsis;
    }

.SearchInput_input__fBAVO:focus,
    .SearchInput_input__fBAVO:focus-visible {
        outline: none;
    }

.SearchInput_input__fBAVO::-webkit-search-cancel-button {
        -webkit-appearance: none;
        cursor: pointer;
    }

@supports (container-type: inline-size) {

.SearchInput_button__4NvmB {
        margin-left: -8px;
        margin-right: -8px;

        width: calc(100% + 16px);
    }
    }

.SearchInput_button__4NvmB:focus,
    .SearchInput_button__4NvmB:focus-visible {
        outline: none;
    }

@container (min-width: 320px) {

.SearchInput_button__4NvmB {
        flex-grow: 0;
        flex-shrink: 0;
        width: auto;
        margin: 0
}
    }

@supports (container-type: inline-size) {

.SearchInput_mobileStackButton__ARhVq {
        margin-left: -16px;
        margin-right: -16px;
        width: 100%;
        margin: 0 auto;
    }
    }

.SearchInput_mobileStackButton__ARhVq:focus,
    .SearchInput_mobileStackButton__ARhVq:focus-visible {
        outline: none;
    }

@container (min-width: 375px) {

.SearchInput_mobileStackButton__ARhVq {
        flex-grow: 0;
        flex-shrink: 0;
        width: auto;
        margin: 0
}
    }


.StickyHeader_header__4tVAd {
    position: relative;
    min-height: 5.0625rem;
    padding: 16px;
    border-width: 0 1px 1px;
    border-style: solid;
    border-color: #303C69;
    border-radius: 0 0 24px 24px;
    background-color: #303C69;
    transition-property: border-color, transform;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    z-index: 500;
}

.StickyHeader_headerSticky__ZlydW {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
}

@media (min-width: 1024px) {

.StickyHeader_headerSticky__ZlydW {
        position: relative
}
    }

.StickyHeader_stickyHeaderPlaceholder___1ePu {
    min-height: 5.0625rem;
    background-color: #303C69;
}

@media (min-width: 768px) {

.StickyHeader_stickyHeaderPlaceholder___1ePu {
        height: 81px
}
    }

@media (min-width: 1024px) {

.StickyHeader_stickyHeaderPlaceholder___1ePu {
        display: none
}
    }

.StickyHeader_container__peJgM {
    position: relative;
    display: flex;
    row-gap: 16px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media (min-width: 768px) {

.StickyHeader_container__peJgM {
        -moz-column-gap:  24px;
             column-gap:  24px
}
    }

.StickyHeader_logo__j_B4W {
    flex-shrink: 0;
    border-radius: 6px;
    order: 1;
}

@media (min-width: 768px) {

.StickyHeader_logo__j_B4W {
        order: 0
}
    }

.StickyHeader_logo__j_B4W:focus {
        outline: 2px solid #007ACC;
        outline-offset: 2px;
    }

.StickyHeader_logo__j_B4W img {
        width: 6.25rem;
    }

@media (min-width: 365px) {

.StickyHeader_logo__j_B4W img {
            width: 7.5rem
    }
        }

.StickyHeader_search__hTWe6 {
    width: 100%;
    order: 3;
}

@media (min-width: 768px) {

.StickyHeader_search__hTWe6 {
        margin-right: -16px;

        display: block;
        order: 0;
        flex-grow: 0;
        width: 15rem;
        transition: flex-grow 300ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    }

@media (min-width: 1024px) {

.StickyHeader_search__hTWe6 {
        width: 19.75rem
}
    }

.StickyHeader_search__hTWe6:focus-within {
        flex-grow: 1;
    }

.StickyHeader_links__C5oBT {
    display: flex;
    flex-shrink: 0;
    order: 2;
    margin-left: auto;
}

@media (min-width: 768px) {

.StickyHeader_links__C5oBT {
        order: 0
}
    }

.StickyHeader_links__C5oBT {

    & > *:last-child;
}

.StickyHeader_link__2tWJ2 {
    font-size: 16px;
    line-height: 140%;

    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 16px;
    border-radius: 6px;
    background: transparent;
    transition-properties: background;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    font-family: sofia-pro, arial, sans-serif;
    font-weight: 700;
    color: #FFFFFF;
}

.StickyHeader_link__2tWJ2:hover {
        background: #2c365e;
    }

.StickyHeader_link__2tWJ2:focus {
        outline: 2px solid #007ACC;
    }

.StickyHeader_signedInIcon__ptFGO {
    position: relative;
}

.StickyHeader_signedInIcon__ptFGO:before {
        content: '';
        position: absolute;
        top: -0.25rem;
        right: -0.25rem;
        width: 12px;
        height: 12px;
        border: 2px solid #303C69;
        border-radius: 100%;
        background-color: #72EDC7;
    }

.StickyHeader_headerScrolled__mWJtg {
    border-color: #435289;
}

.ExitCallout_wrapper__A1lav {
    padding: 8px;
    background: #CCF2FF;
    border-radius: 24px;
    text-align: center;
}

    @media (min-width: 768px) {.ExitCallout_wrapper__A1lav {
        padding: 16px
}
    }

.ExitCallout_content__ymFdm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16px * var(--tw-space-y-reverse));
}

.ExitCallout_content__ymFdm {

    padding: 24px 12px;
    border: 1px solid var(--border-color, #007acc80);
    border-radius: 24px;
}

@media (min-width: 768px) {

.ExitCallout_content__ymFdm {
        padding: 48px 32px
}
    }

.ExitCallout_content__ymFdm > * {
        max-width: 38rem;
        margin-left: auto;
        margin-right: auto;
    }

.AuthorMeta_authorMeta__zIwPs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(32px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(32px * var(--tw-space-y-reverse));
}

.AuthorMeta_authorMeta__zIwPs {
    --tw-bg-opacity: 1;
    background-color: rgb(248 248 248 / var(--tw-bg-opacity));
    padding-top: 32px;
    padding-bottom: 32px;
}

@media (min-width: 768px) {

    .AuthorMeta_authorMeta__zIwPs {
        padding-top: 48px;
        padding-bottom: 48px;
    }
}

.AuthorMeta_container__vJxRM {
    max-width: 1152px;
    padding: 0 16px;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 768px) {

.AuthorMeta_container__vJxRM {
        padding: 0 24px
}
    }

.AuthorMeta_metadata__WWHMN {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;

    border-bottom: 1px solid #CAD8E2;
}

.AuthorMeta_metadata__WWHMN:last-of-type {
        border-bottom: none;
    }

.AuthorMeta_authorInfo__BcZAo > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12px * var(--tw-space-y-reverse));
}

.AuthorMeta_authorInfo__BcZAo {
    padding-bottom: 32px;
}

@media (min-width: 768px) {

    .AuthorMeta_authorInfo__BcZAo {
        padding-bottom: 0;
    }
}

.AuthorMeta_linksContainer__hUegE {
    grid-column: span 12 / span 12;
    padding-bottom: 16px;
}

@media (min-width: 768px) {

    .AuthorMeta_linksContainer__hUegE {
        grid-column: span 4 / span 4;
    }
}

.AuthorMeta_linksContainer__hUegE {

    border-bottom: 1px solid #CAD8E2;
}

@media (min-width: 768px) {

.AuthorMeta_linksContainer__hUegE {
        padding-bottom: 0;

        border-bottom: none;
    }
    }

.AuthorMeta_linksContainer__hUegE:last-of-type {
        border-bottom: none;
    }

